import React from 'react';
import styled from 'styled-components';

type LabelProps = {
  children?: React.ReactNode;
  className?: string;
};

const Label = ({
  children,
  className,
} : LabelProps) => (
  <LabelContainer
    className={`
      ${className || ''}
    `}
  >
    <span>
      { children }
    </span>
  </LabelContainer>
);

const LabelContainer = styled.div`
  color: #888888;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  padding: 0 0.5rem;
  text-transform: uppercase;

  & span {
    margin-left: 4px;
  }
`;

export default Label;
