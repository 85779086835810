import React, { useState, useEffect, useRef, useCallback } from 'react';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import Downshift from 'downshift';
import { useTheme } from '../../hooks';
import { Theme } from '../../theme';
import TextInput from '../TextInput';
import Icon, { iconKeyType } from '../Icon';
import Surface from '../Surface';
import _ from 'lodash';


const Menu: React.FC<{ children: React.ReactNode, theme: Theme }> = React.forwardRef((props, ref) => (
  <BaseMenu innerRef={ref} {...props} tint="primary" />
));


function getItems(items: Array<OPTION_TYPE>, filter: string):Array<OPTION_TYPE> {
  return filter
    ? items.filter(item => 
      (
        (typeof item === 'string' || item instanceof String) ? item : item.label
      ).toLowerCase().indexOf(filter.toLowerCase()) !== -1
      )
    : items
}

export type OPTION_TYPE = {
  value: string | number,
  label: string,
  subLabel?: string,
  url?: string,
  imageUrl?: string,
} | string;

export type TypeaheadProp = {
  onSelect?: (selectedItem: OPTION_TYPE | null) => void | Function;
  onClear?: () => void | Function;
  className?: string;
  containerClassName?: string;
  size?: 'small' | 'medium' | 'large' | 'x-large';
  icon?: iconKeyType;
  clearIcon?: iconKeyType;
  loading?: boolean;
  placeholder?: string;
  // New
  items?: Array<OPTION_TYPE>;
  initialSelectedItem?: OPTION_TYPE;
  renderItem?: (option: OPTION_TYPE, idx: number, itemProps?: any) => JSX.Element;
  renderSelectedItem?: (option: OPTION_TYPE, onReset: Function) => JSX.Element;
  fetchItems?: (word: string) => Promise<Array<OPTION_TYPE>>;
  inputDebounce?: number;
  tint?: 'primary' | 'secondary' | 'warning' | 'info' | 'success' | undefined;
  hover?: 'primary' | 'secondary' | 'warning' | 'info' | 'success' | undefined;
  fullWidth?: boolean;
  autoFocus?: boolean;
  value?: OPTION_TYPE | null;
};


const Typeahead = ({
  onSelect,
  onClear,
  renderItem,
  renderSelectedItem,
  placeholder = 'Buscar...',
  tint = 'primary',
  hover = 'primary',
  className = '',
  containerClassName = '',
  icon = 'search',
  clearIcon = 'times',
  size = 'medium',
  items = [],
  initialSelectedItem,
  loading = false,
  inputDebounce = 0,
  fetchItems,
  fullWidth = false,
  autoFocus = false,
  // For controlled element
  value = undefined,
}: TypeaheadProp) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false || autoFocus);
  const [isLoading, setIsLoading] = useState<boolean>(loading);
  const [optionItems, setOptionItems] = useState<Array<OPTION_TYPE>>(items || []);
  const [currentValue, setCurrentValue] = useState<OPTION_TYPE | undefined | null>(initialSelectedItem);
  const [searchValue, setSearchValue] = useState('');
  const node = useRef<any>(null);
  const input = useRef<any>(null);
  const suggestionsNode = useRef<any>(null);

  const itemToString = (item: any) => item ?
    (typeof item === 'string' || item instanceof String)
    ? item.toString()
    : item.label || item.toString()
  : '';

  const onInputChange = () => {
    return (stringValue: string) => {
      if (fetchItems) setIsLoading(true);
      fetchItems
        ? fetchItems(stringValue)
          .then((items: any) => {
            setOptionItems(items);
            setIsLoading(false);
          })
          .catch(() => {
            setOptionItems([]);
            setIsLoading(false);
          })
        : () => {};
    }
  }

  const clearSelectedItem = (clearSelection: Function | void) => (
    () => {
      if (clearSelection) clearSelection();
      if (onClear) onClear();
    }
  ) 

  const defaultRenderItem = (option: OPTION_TYPE, idx: number, itemProps?: any) => (
    <Element
      key={idx}
      theme={theme}
      tint={tint}
      hover={hover}
      {...itemProps}
    >
      <label>
        { 
          (typeof option === 'string' || option instanceof String)
            ? option
            : option.label
        }
      </label>
    </Element>
  )

  const debouncedOnInputChange = useCallback(
    debounce(onInputChange(), inputDebounce)
  , []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value || '');
    debouncedOnInputChange(e.target.value);
  }

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      debouncedOnInputChange.cancel();
    }
  }, []);

  const handleOnSelect = (item: OPTION_TYPE | null) => {
    if (item && onSelect) onSelect(item);
    setCurrentValue(item || undefined);
    setSearchValue('');
    input.current?.blur();
    setOpen(false);
  }

  const handleTypeaheadControllerIcon = () => {
    input.current?.focus();
    if (currentValue) setCurrentValue(undefined);
  }

  const TypeaheadControllerIcon = (icon: iconKeyType = 'search', clearSelection: Function) => <Icon
    iconSize="sm"
    iconName={icon}
    onClick={() => {
      handleTypeaheadControllerIcon();
      if (clearSelection) {
        clearSelection();
        if (onClear) onClear();
      }
    }}
  />

  const LoaderControllerIcon = () => <Icon
    iconSize="sm"
    iconName="spinner"
    className="fa-pulse"
  />

  const handleClickOutside = (e: Event) => {
    if (node.current?.contains(e.target) || suggestionsNode.current?.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    if(value !== undefined) setCurrentValue(value);
    if(autoFocus && open) input.current?.focus();

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, value]);

  return (
    <Downshift
      initialSelectedItem={initialSelectedItem}
      onSelect={handleOnSelect}
      isOpen={open}
      defaultHighlightedIndex={0}
      itemToString={itemToString}
    >
      {({
        // getInputProps,
        getRootProps,
        getItemProps,
        clearSelection,
        selectedItem,
      }) => (
        <TypeaheadContainer className={containerClassName} fullWidth={fullWidth} {...getRootProps()}>
          <TypeaheadInputContainer
            ref={node}
            onClick={() => {
              setTimeout(() => {
                setOpen(true)
                if (!open) input.current?.focus();
              });
            }}
            tint={tint}
            theme={theme}
            variant="outlined"
            className={`
              ${className}
              ${size}
              ${open ? 'open': ''}
              typeaheadInputContainer
            `}
          >
            {
              (selectedItem || initialSelectedItem) && currentValue && renderSelectedItem ? (
                // Default renderSelectedItem & renderItem
                renderSelectedItem(currentValue, clearSelectedItem(clearSelection))
              ) : (
                <TextInput
                  ref={input}
                  disabled={currentValue !== undefined && value !== null}
                  color={tint}
                  value={currentValue ? currentValue.toString() : searchValue}
                  buttonContent="Buscar"
                  buttonPosition="right"
                  elevation={0}
                  variant="non-lined"
                  isSquare={false}
                  placeholder={placeholder}
                  renderRight={
                    isLoading
                    ? LoaderControllerIcon()
                    : TypeaheadControllerIcon(!currentValue ? icon : clearIcon, clearSelectedItem(clearSelection))
                  }
                  fullWidth={fullWidth}
                  size={size}
                  styleFocus={true}
                  onChange={handleChange}
                  autoFocus={autoFocus}
                />
              )
            }
          </TypeaheadInputContainer>
          <TypeaheadMenuContainer
            ref={suggestionsNode}
          >
            <Menu
              theme={theme}
            >
              {
                open && !currentValue && searchValue !== ''
                ? getItems(optionItems, searchValue).map((item, index) => (
                  renderItem
                    ? renderItem(item, index, {...getItemProps({
                      item: item,
                      index,
                      isSelected: selectedItem === item,
                      color: hover,
                    })})
                    : defaultRenderItem(item, index, {...getItemProps({
                      item: item,
                      index,
                      isSelected: selectedItem === item,
                      color: hover,
                    })})
                ))
                : null
              }
            </Menu>
          </TypeaheadMenuContainer>
        </TypeaheadContainer>
      )}
    </Downshift>
  );
};


/* 
 UI Elements
*/

type ElementProps = {
  isActive?: boolean;
  isSelected?: boolean;
  tint: 'primary' | 'secondary' | 'warning' | 'info' | 'success' | 'error' ;
  hover?: 'primary' | 'secondary' | 'warning' | 'info' | 'success' | 'error' ;
  innerRef?: any;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

type StylesProps = {
  theme: Theme;
  tint: string;
  hover?: string;
};

type TypeaheadContainerProps = {
  fullWidth: boolean;
};

const BaseMenu = styled('ul')<ElementProps>`
  margin-top: 0;
  background-color: white;
  width: 100%;
  outline: '0';
  transition: 'opacity .1s ease';
  border-color: '#96c8da';
  border-top-width: '0';
  border-right-width: 1;
  border-bottom-width: 1;
  border-left-width: 1;
  border-style: 'solid';
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.gray.paleGray};
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  font-size: 90%;
  max-height: calc(60px * 3);
  overflow: auto;
  padding: 0;
  position: absolute;
  z-index: 3;
`;

export const SelectedElement = styled('div')<ElementProps>`
  font-family: 'Open Sans', sans-serif;
  background-color: ${({ theme }) => theme.neutral.white};
  color: hsl(180, 3%, 17%);
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.75rem;
  padding-left: 1rem;
  user-select: none;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  min-width: 190px;

  label {
    color: ${({ tint, theme }) => theme[tint].main};
  }

  p {
    margin: 0;
  }
`;

export const Element = styled('div')<ElementProps>`
  font-family: 'Open Sans', sans-serif;
  color: hsl(180, 3%, 17%);
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.75rem;
  padding-left: 1rem;
  user-select: none;
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    color: ${({ tint, theme }) => theme[tint].main};
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  p {
    margin: 0;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  &:hover, &.highlighted {
    background: ${({ tint, hover, theme }) => hover ? theme[hover].main : theme[tint].main};
  }

  &:hover *, &.highlighted * {
    color: white !important;
  }
`;

export const ElementWithImage = styled(Element)`
  display: block;
  border-radius: 5px;

  &.selected {
    background-color: ${({ theme }) => theme.neutral.white};
    width: 100%;
    min-width: 190px;

    &:hover * {
      color: ${({ tint, hover, theme }) => hover ? theme[hover].main : theme[tint].main} !important;
      p {
        color: hsl(180, 3%, 17%) !important;
      }
    }
  }
`;

export const ElementInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.75rem;
  width: 65%;

  &.selected {
    width: 57%;
  }
`;

export const ElementInnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const SubElement = styled.p`
  font-weight: 100;
  margin-top: 3px !important;
  font-size: 0.7rem;
`;

export const ElementClear = styled.div<ElementProps>`
  color: ${({ tint, theme }) => theme[tint].main};
  left: auto;
  right: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2 !important;

  svg {
    color: ${({ tint, theme }) => theme[tint].main};
  }
`

const TypeaheadContainer = styled.div<TypeaheadContainerProps>`
  height: fit-content;
  max-width: ${props => props.fullWidth ? '100%' : '220px'};
  width: ${props => props.fullWidth ? '100%' : ''};
`

const TypeaheadMenuContainer = styled.div`
  position: relative;
`

const TypeaheadInputContainer = styled(Surface)<StylesProps>`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background: ${({ theme }) => theme.gray.paleGray};
  color: black;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  box-sizing: border-box;
  transition: ${({ theme }) => theme.transitions.default};
  /* max-width: 220px; */

  &.small {
    min-height: calc(2rem - 2px);
  }
  &.medium {
    min-height: calc(2.5rem - 2px);
  }
  &.large {
    min-height: calc(3rem - 2px);
  }
  &.x-large {
    min-height: calc(4rem - 2px);
  }
  &.open, &:hover {
    background: ${({ theme }) => theme.neutral.white};
  }
  &.open, &.hasError {
    outline: none;
    border-color: ${({ tint, theme }) => theme[tint].main} !important;
    // box-shadow: 0px 0px 0px 1px ${({ tint, theme }) => theme[tint].main};
  }
`;


export default Typeahead;
