import React, { forwardRef, ReactNode } from 'react';
import styled from 'styled-components';
import { useTheme } from '../../hooks';

export type SurfaceProps = {
  variant?: 'elevated' | 'outlined';
  /** Elevation level from 0 to 24. */
  borderRadiusType?: 'container' | 'element';
  elevation?: any;
  square?: boolean;
  className?: string;
  classes?: string;
  children?: ReactNode;
  component?: any;
} & React.HTMLAttributes<HTMLDivElement>;

const Surface = forwardRef((props: SurfaceProps, ref: any) => {
  const theme = useTheme();
  const {
    variant = 'elevated',
    elevation = 1,
    square = false,
    className,
    borderRadiusType = 'element',
    component = 'div',
    ...otherProps
  } = props;

  return (
    <SurfaceBase
      className={`
        ${variant}
        ${square ? 'square' : 'rounded'}
        ${className || ''}
      `}
      surfaceBackground={theme.background.paper}
      elevation={theme.shadows[elevation]}
      dividerColor={theme.divider}
      radius={
        borderRadiusType === 'container'
        ? theme.radius.containerBorderRadius
        : theme.radius.elementBorderRadius
      }
      ref={ref}
      as={component}
      {...otherProps}
    />
  );
});

const SurfaceBase = styled.div<any>`
  background: ${props => props.surfaceBackground};
  &.square {
    border-radius: 0;
  };
  &.rounded {
    border-radius: ${props => props.radius};
  };
  &.elevated {
    box-shadow: ${props => props.elevation};
  };
  &.outlined {
    border: 1px solid ${props => props.dividerColor};
  };
  ${props => props.classes}
`;

export default Surface;
