import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTheme } from '../../hooks';

type LoaderPropTypes = {
  className?: string;
  tint?: 'neutral' | 'primary' | 'secondary' | 'warning' | 'info' | 'success' | 'error' | 'white' | 'gray';
  size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
  isRound?: boolean;
  text?: string;
  textPosition?: 'top' | 'bottom' | 'start' | 'end';
  rightSpace?: boolean;
};

const Loader = ({
  size = 'medium',
  tint = 'white',
  className = '',
  isRound,
  text,
  textPosition = 'bottom',
  rightSpace: rightSpace = false,
}: LoaderPropTypes) => {
  const theme: any = useTheme();
  let color = '#fff';

  if (tint === 'white') {
    color = '#fff';
  } else if (tint === 'gray') {
    color = '#c3c3c3';
  } else {
    color = theme[tint]?.main;
  }

  return (
    <LoaderTextContainer
      className={`
        loaderTextContainer
        ${textPosition}
        ${size}
      `}
    >
      <LoaderContainer
        className={`
          loaderContainer
          ${size}
          ${isRound ? 'roundLoader' : ''}
          ${className}
        `}
        color={color}
        rightSpace={rightSpace}
      >
        <div className="bounceOne" />
        <div className="bounceTwo" />
        <div />
      </LoaderContainer>
      {
        text ? (
          <span>
            {text}
          </span>
        ) : null
      }
    </LoaderTextContainer>
  );
}

const bounceAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
    opacity: 0;
  }
  40% {
    transform: scale(1.0);
    opacity: 1;
  }
`;

const LoaderTextContainer = styled.div<any>`
  display: flex;
  font-family: 'Open Sans', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  width: fit-content;
  align-items: center;
  justify-content: center;
  &.x-small {
    font-size: .7rem;
  }
  &.small {
    font-size: .8rem;
  }
  &.medium {
    font-size: .9rem;
  }
  &.large {
    font-size: 1rem;
  }
  &.x-large {
    font-size: 1.1rem;
  }
  & > span {
    margin-top: 8px;
  }
  &.top {
    flex-direction: column-reverse;
  }
  &.bottom {
    flex-direction: column;
  }
  &.start {
    flex-direction: row-reverse;
  }
  &.end {
    flex-direction: row;
  }
`;

const LoaderContainer = styled.div<any>`
  display: flex;
  opacity: ${props => props.color === 'grey' ? 1 : 0.75};
  & > div {
    background: ${(props: any) => props.color};
  }
  & + span {
    color:  ${(props: any) => props.color};
  }
  margin-right: ${props => props.rightSpace ? '.5rem' : '0'};
  & > .bounceOne {
    animation-delay: -0.32s;
  }
  & > .bounceTwo {
    animation-delay: -0.16s;
  }
  & > div {
    margin: 0.125rem;
    border-radius: calc(0.5rem*0.25);
    display: inline-block;
    animation: ${bounceAnimation} 1.4s infinite ease-in-out both;
  }
  &.x-small > div {
    width: 0.3rem;
    height: 1rem;
  }
  &.small > div {
    width: 0.4rem;
    height: 1.4rem;
  }
  &.medium > div {
    width: 0.6rem;
    height: 1.8rem;
  }
  &.large > div {
    width: 0.8rem;
    height: 2.2rem;
  }
  &.x-large > div {
    width: 1rem;
    height: 2.6rem;
  }
  &.roundLoader > div {
    border-radius: 0.5rem;
  }
`;

export default Loader;
