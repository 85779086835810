import React, { useState } from 'react';
import styled from 'styled-components';

import { useTheme } from '../../hooks';
import { Theme } from '../../theme';
import Button from '../Button';
import InputBase from '../InputBase';
import Label from '../Label';
import Surface from '../Surface';

export type TextInputProp = {
  size?: 'x-large' | 'large' | 'medium' | 'small';
  color?: 'primary' | 'secondary' | 'warning' | 'info' | 'success';
  variant?: 'outlined' | 'underlined' | 'non-lined';
  buttonColorVariant?: 'main' | 'light' | 'dark';
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  isSquare?: boolean;
  elevation?: number;
  isLoading?: boolean;
  autoFocus?: boolean;
  renderLeft?: React.ReactNode;
  renderRight?: React.ReactNode;
  placeholder?: string;
  label?: string;
  helperText?: string;
  showButton?: boolean;
  buttonPosition?: 'left' | 'right';
  buttonContent?: React.ReactNode;
  hasError?: boolean;
  name?: string;
  title?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  inputContainerRef?: React.Ref<any>;
  onFocus?: Function;
  onBlur?: Function;
  styleFocus?: boolean,
};

const TextInput = React.forwardRef(({
  size = 'medium',
  color = 'primary',
  buttonColorVariant = 'main',
  variant: inputVariant = 'outlined',
  value,
  onChange,
  className = '',
  isSquare = false,
  elevation = 1,
  isLoading = false,
  autoFocus = false,
  renderLeft,
  renderRight,
  placeholder,
  label,
  helperText,
  showButton = false,
  buttonPosition = 'right',
  buttonContent,
  hasError = false,
  name,
  title,
  fullWidth = false,
  disabled = false,
  onFocus,
  styleFocus = true,
  onBlur,
  inputProps,
  inputContainerRef,
}: TextInputProp, ref: React.Ref<any>) => {
  const theme = useTheme();
  const [focused, setFocused] = useState(false);
  const calculatedProps: any = {};
  if (value || value === '') calculatedProps['value'] = value;
  if (onChange) calculatedProps['onChange'] = onChange;

  return (
    <TextFieldContainer className={className} fullWidth={fullWidth}>
      {
        label && (
          <Label>
            {label}
          </Label>
        )
      }
      <ButtonsContainer className="buttonsContainer">
        {
          showButton && buttonPosition === 'left' && (
            <ButtonLeft
              isLoading={isLoading}
              className="buttonLeft"
              size={size}
              color={hasError ? 'error' : color}
              colorVariant={buttonColorVariant}
            >
              {buttonContent}
            </ButtonLeft>
          )
        }
        <InputContainer
          title={title}
          variant="outlined"
          theme={theme}
          square={isSquare || inputVariant === 'underlined'}
          elevation={elevation}
          inputColor={hasError ? 'error': color}
          className={`
            ${size}
            ${
              // Easy way to match multiple styles based on the input variant
              `paperInput${inputVariant
                .toLowerCase()
                .replace(/(?:^|[\s-/])\w/g, (match) => match.toUpperCase())
                .replace(/[\s-/]/, '')
            }`}
            ${focused && 'focused'}
            ${showButton && buttonPosition === 'left' && 'buttonLeft'}
            ${showButton && buttonPosition === 'right' && 'buttonRight'}
            ${renderLeft && 'renderLeft'}
            ${renderRight && 'renderRight'}
            ${hasError && 'hasError'}
            ${styleFocus && 'focusable'}
            inputContainer
          `}
          ref={inputContainerRef}
        >
          {
            renderLeft && renderLeft
          }
          <Input
            autoFocus={autoFocus}
            placeholder={placeholder}
            onFocus={() => {
              setFocused(true);
              if (onFocus) onFocus();
            }}
            onBlur={() => {
              setFocused(false);
              if (onBlur) onBlur();
            }}
            disabled={disabled}
            className={`
            ${size}
            textInput
            `}
            ref={ref}
            inputProps={{
              name: name,
              ...inputProps,
            }}
            {...calculatedProps}
          />
          {
            renderRight && renderRight
          }
        </InputContainer>
        {
          showButton && buttonPosition === 'right' && (
            <ButtonRight
              isLoading={isLoading}
              className="buttonRight"
              size={size}
              color={hasError ? 'error': color}
              colorVariant={buttonColorVariant}
            >
              {buttonContent}
            </ButtonRight>
          )
        }
      </ButtonsContainer>
      {
        helperText && (
          <HelperText>
            {helperText}
          </HelperText>
        )
      }
    </TextFieldContainer>
  );
});

type StylesProps = {
  theme: Theme;
}

type InputContainerStylesProps = {
  inputColor: 'primary' | 'secondary' | 'warning' | 'info' | 'success' | 'error';
} & StylesProps;

type TextFieldContainerProps = {
  fullWidth: boolean;
} & StylesProps;

const Input = styled(InputBase)`
  font-family: 'Open Sans', sans-serif;
  color: #6f7677;
  background: transparent;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  outline: none;
  &::placeholder {
    opacity:0.25;
  }
  &.small {
    min-height: calc(2rem - 2px);
  }
  &.medium {
    min-height: calc(2.5rem - 2px);
  }
  &.large {
    min-height: calc(3rem - 2px);
  }
  &.x-large {
    min-height: calc(4rem - 2px);
  }
`;

const HelperText = styled.div`
  color: #888888;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.6rem;
  font-weight: bold;
  margin-top: 0.5rem;
  padding: 0 0.5rem;

  & span {
    margin-left: 4px;
  }
`;

const TextFieldContainer = styled.div<TextFieldContainerProps>`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  height: fit-content;
  width: ${props => props.fullWidth ? '100%' : 'fit-content'};
`;

const ButtonsContainer = styled.div<StylesProps>`
  display: flex;
  margin: 0;
  padding: 0;
`;

const ButtonLeft = styled(Button)`
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
`;

const ButtonRight = styled(Button)`
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
`;

const InputContainer = styled(Surface)<InputContainerStylesProps>`
  display: flex;
  align-items: center;
  color: #6f7677;
  background: ${({ theme }) => theme.gray.paleGray};
  width: 100%;
  height: fit-content;
  transition: ${({ theme }) => theme.transitions.default};
  &.small {
    min-height: calc(2rem - 2px);
  }
  &.medium {
    min-height: calc(2.5rem - 2px);
  }
  &.large {
    min-height: calc(3rem - 2px);
  }
  &.x-large {
    min-height: calc(4rem - 2px);
  }
  &.buttonLeft {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: 0;
  }
  &.buttonRight {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }
  &.renderLeft {
    & > input {
      padding: 0 0.8rem 0 0;
    }
  }
  &.renderRight {
    & > input {
      padding: 0 0 0 0.8rem;
    }
  }
  &.paperInputUnderlined {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top-width: 0 !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    background: white;
  }

  &.paperInputNonLined {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 0 !important;
    border-top-width: 0 !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    border-bottom-width: 0 !important;
    background: white;
  }

  &:hover, &:focus-within {
    background: ${({ theme }) => theme.neutral.white};
  }
  &.focusable:focus-within, &.focusable.hasError {
    outline: none;
    border-color: ${({ inputColor, theme }) => theme[inputColor].main} !important;
  }
  &.paperInputUnderlined:hover, &.paperInputUnderlined:focus-within {
    // box-shadow: 0px 1px 0px 0px ${({ inputColor, theme }) => theme[inputColor].main};
  }
  &.paperInputOutlined:hover, &.paperInputOutlined:focus-within {
    // box-shadow: 0px 0px 0px 1px ${({ inputColor, theme }) => theme[inputColor].main};
  }
  &.focusable:focus-within, &.focusable.hasError {
    & svg {
      color: ${({ inputColor, theme }) => theme[inputColor].main};
      opacity: 1;
      transform: scale(1);
    }
  }
  & svg {
    color: #6f7677;
    opacity: 0.6;
    padding: 0 0.8rem 0 0.8rem;
    transition: ${({ theme }) => theme.transitions.default};
  }
`;

export default TextInput;
