import React from 'react';
import styled from 'styled-components';
import chroma from 'chroma-js';

import ButtonBase from '../ButtonBase';
import useTheme from '../../hooks/useTheme';
import Loader from '../Loader';

type ButtonProps = {
  children?: React.ReactNode;
  size?: 'x-large' | 'large' | 'medium' | 'small';
  color?: 'neutral' | 'primary' | 'secondary' | 'warning' | 'info' | 'success' | 'error' | 'gray';
  colorVariant?: 'main' | 'light' | 'dark';
  variant?: 'default' | 'text' | 'outlined' | 'icon' | 'filled-icon';
  className?: string;
  classes?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
};

const Button = React.forwardRef(({
  children,
  color = 'primary',
  colorVariant = 'main',
  className = '',
  variant = 'default',
  size = 'medium',
  onClick,
  onMouseEnter,
  onMouseLeave,
  startIcon = null,
  endIcon = null,
  disabled,
  isLoading,
  fullWidth = false,
  ...props
}: ButtonProps, ref) => {
  const theme: any = useTheme();
  const baseColor: string = theme[color][color === 'gray' && 'gray' || color === 'neutral' && 'white' || colorVariant];
  const activeColor = chroma.mix(baseColor, theme[color][color === 'gray' && 'gray' || color === 'neutral' && 'white' || 'light'], 0.1, 'rgb');
  const hoverFilter: string = theme.buttons.hover;
  const disabledColor: string = theme.disabled;
  const hoverColor = theme.mode === 'dark'
    ? chroma.mix(baseColor, hoverFilter, 0.1, 'rgb')
    : chroma.mix(baseColor, hoverFilter, 0.1, 'rgb');

  return (
    <ButtonComp
      className={`
        ${size}
        ${variant}
        ${className}
      `}
      color={color}
      disabled={disabled}
      activeColor={activeColor}
      transition={theme.transitions.default}
      baseColor={baseColor}
      disabledColor={disabledColor}
      hoverColor={hoverColor}
      hoverShadow={theme.shadows[1]}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      size={size}
      isLoading={isLoading}
      full={fullWidth}
      ref={ref}
      {...props}
    >
      {startIcon &&
        !isLoading &&
        variant !== 'icon' &&
        variant !== 'filled-icon' &&
        (
          <StartIconContainer>
            {startIcon}
          </StartIconContainer>
        )
      }
      { isLoading && (
        <Loader
          size="x-small"
          rightSpace={true}
          tint={
            disabled || color === 'gray'
              ? 'gray'
              : variant === 'default'
                ? 'white'
                : color
          }
        />
      )}
      {children}
      {endIcon &&
        !isLoading &&
        variant !== 'icon' &&
        variant !== 'filled-icon' &&
        (
          <EndIconContainer>
            {endIcon}
          </EndIconContainer>
        )
      }
    </ButtonComp>
  );
});

const ButtonComp = styled(ButtonBase)<any>`
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  width: ${props => props.full ? '100%' : 'fit-content'};
  color: ${props => props.color === 'gray' && '#6a696e' || props.color === 'neutral' && '#a3a6a6' || '#fff'};
  transition: ${props => props.transition};
  border: ${props => props.color === 'gray' ? '1px solid #d8d6e0' : 'none'};
  box-sizing: border-box;

  & svg {
    color: inherit;
  }

  &.icon {
    border-radius: 100px !important;
    &.small {
      height: 2rem;
      width: 2rem;
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
    &.medium {
      height: 2.5rem;
      width: 2.5rem;
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    &.large {
      height: 3rem;
      width: 3rem;
      svg {
        width: 2rem;
        height: 2rem;
      }
    }
    &.x-large {
      height: 4rem;
      width: 4rem;
      svg {
        width: 3rem;
        height: 3rem;
      }
    }
  }  
  &.filled-icon {
    border-radius: 100px;
    &.small {
      height: 2rem;
      width: 2rem;
      svg {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
    &.medium {
      height: 2.5rem;
      width: 2.5rem;
      svg {
        width: 1.3rem;
        height: 1.3rem;
      }
    }
    &.large {
      height: 3rem;
      width: 3rem;
      svg {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
    &.x-large {
      height: 4rem;
      width: 4rem;
      svg {
        width: 2.8rem;
        height: 2.8rem;
      }
    }
  }
  &.default, &.filled-icon {
    background: ${(props: any) => props.baseColor};
  }
  &.default:hover, &.filled-icon:hover {
    background: ${props => props.hoverColor};
    box-shadow: ${props => props.hoverShadow};
  };
  &.default.disabled, &.filled-icon.disabled {
    background: ${(props: any) => chroma(props.disabledColor).alpha(0.2).css()};
    color: ${(props: any) => props.disabledColor} !important;
    box-shadow: none !important;
  }
  &.default:active:not(.disabled), &.filled-icon:active:not(.disabled) {
    background: ${props => props.activeColor};
  }
  &.text, &.icon {
    background: transparent;
    color: ${(props: any) => props.baseColor};
  }
  &.text:hover, &.icon:hover {
    background: ${(props: any) => chroma(props.baseColor).alpha(0.1).css()};
    box-shadow: none !important;
  }
  &.text.disabled, &.icon.disabled {
    background: transparent;
    color: ${(props: any) => props.disabledColor};
    box-shadow: none !important;
  }
  &.filled-icon.disabled {
    color: ${(props: any) => props.disabledColor};
    box-shadow: none !important;
  }
  &.text:active:not(.disabled), &.icon:active:not(.disabled), &.filled-icon:active:not(.disabled) {
    background: ${props => chroma(props.activeColor).alpha(0.1).css()};
  }
  &.outlined {
    background: transparent;
    border: 2px solid ${(props: any) =>props.baseColor};
    color: ${(props: any) => props.baseColor};
  }
  &.outlined:hover {
    background: ${(props: any) => chroma(props.baseColor).alpha(0.1).css()};
    box-shadow: none !important;
  }
  &.outlined.disabled {
    background: transparent;
    color: ${(props: any) => props.disabledColor};
    border-color: ${(props: any) => props.disabledColor};
    box-shadow: none !important;
  }
  &.outlined:active:not(.disabled) {
    background: ${props => chroma(props.activeColor).alpha(0.1).css()};
  }
  &.small:not(.icon):not(.filled-icon) {
    height: 2rem;
    border-radius: 0.5rem;
    padding: 4px 18px;
    font-size: 0.8rem;
  }
  &.medium:not(.icon):not(.filled-icon) {
    height: 2.5rem;
    border-radius: 0.5rem;
    padding: 6px 22px;
    font-size: 0.85rem;
  }
  &.large:not(.icon):not(.filled-icon) {
    height: 3rem;
    border-radius: 0.5rem;
    padding: 8px 26px;
    font-size: 0.9rem;
  }
  &.x-large:not(.icon):not(.filled-icon) {
    height: 4rem;
    border-radius: 0.5rem;
    padding: 10px 30px;
    font-size: 1rem;
  }
  ${(props: any) => props.classes}
`;

const StartIconContainer = styled.span`
  display: inherit;
  margin-right: 0.5rem;

  & svg {
    color: inherit;
  }
`;

const EndIconContainer = styled.span`
  display: inherit;
  margin-left: 0.5rem;

  & svg {
    color: inherit;
  }
`;

export default Button;
