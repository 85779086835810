export type Theme = {
  mode?: 'dark' | 'light';
  disabled?: string;
  primary: {
    main: string;
    light: string;
    dark: string;
    extra: string;
  };
  secondary: {
    main: string;
    light: string;
    dark: string;
    extra: string;
  };
  error: {
    main: string;
    light: string;
    dark: string;
  };
  warning: {
    main: string;
    light: string;
    dark: string;
  };
  info: {
    main: string;
    light: string;
    dark: string;
  };
  success: {
    main: string;
    light: string;
    dark: string;
  };
  background: {
    default: string;
    paper: string;
  };
  buttons?: {
    hover: string;
    disabled: string;
    active: string;
  };
  typography?: {
    primary: string;
    secondary: string;
    disabled: string;
  };
  divider?: string;
  neutral: {
    [key: string]: string;
  },
  gray: {
    [key: string]: string;
  },
  shadows: {
    [key: string]: string;
  },
  radius: {
    elementBorderRadius: string;
    containerBorderRadius: string;
  },
  transitions?: {
    default: string,
  }
};

export const baseTheme: Theme = {
  primary: {
    main: '#009FA0',
    light: '#5EBEBD',
    dark: '#007378',
    extra: '#003D41',
  },
  secondary: {
    main: '#E9A037',
    light: '#FCC567',
    dark: '#BB802A',
    extra: '#AEDCDA',
  },
  error: {
    main: '#E24C4B',
    light: '#E97777',
    dark: '#BF4443',
  },
  warning: {
    main: '#ff9800',
    light: '#ffad33',
    dark: '#cc7a00',
  },
  info: {
    main: '#2196f3',
    light: '#64b5f6',
    dark: '#1976d2',
  },
  success: {
    main: '#149341',
    light: '#1cb15a',
    dark: '#4CA84C',
  },
  disabled: '#C3C3C3',
  neutral: {
    white: '#FFF',
    paleSky: '#ECF4F4',
    lightSky: '#E0ECEB',
    graySky: '#D3E3E2',
    mediumSky: '#8BA09F',
    darkSky: '#566463',
    blackSky: '#2E3837',
  },
  gray: {
    paleGray: '#F7F7F7',
    lightGray: '#EDEDED',
    gray: '#E3E3E3',
    mediumGray: '#C3C3C3',
    darkGray: '#6F7677'
  },
  background: {
    default: '#FFF',
    paper: '#FFF',
  },
  shadows: {
    0: "none",
    1: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    2: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    3: "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    boxShadow: "0 1px 1px rgba(91, 92, 92, 0.1)",
    boxShadow2: "0 3px 4px rgba(50, 50, 50, 0.25)",
    layerBoxShadow: "0 0 4px 1px rgba(91, 92, 92, 0.15)",
    layerBoxShadow2: "0 0 8px 1px rgba(91, 92, 92, 0.10)",
  },
  radius: {
    elementBorderRadius: '0.25rem',
    containerBorderRadius: '1rem',
  },
  transitions: {
    default: '200ms ease-in-out',
  }
}
