import React from 'react';
import styled from 'styled-components';

import Step, { STEP_TYPE } from '../Step';
import { iconKeyType } from '../Icon';

import { useTheme } from '../../hooks';

export type StepperProps = {
  children?: React.ReactNode;
  steps?: Array<STEP_TYPE>;
  activeStep?: number;
  inline?: boolean;
  disabledSteps?: Array<number>;
  size?: 'x-large' | 'large' | 'medium' | 'small';
  color?: 'primary' | 'secondary' | 'warning' | 'info' | 'success';
  variant?: 'dark' | 'main' | 'light';
  iconName?: iconKeyType;
  inactiveIconName?: iconKeyType;
  className?: string;
};

const Stepper = ({
  steps = [],
  activeStep = 0,
  disabledSteps = [],
  size = 'medium',
  color = 'primary',
  variant = 'main',
  iconName = "checkCircle",
  inactiveIconName = "solidCircle",
  inline = false,
  className = '',
}: StepperProps) => {
  const theme = useTheme();

  return (
    <StepperContainer
      className={`
        ${size}
        ${className}
      `}
    >
      {
        steps.length > 0 ? steps.map((item, index) => (
          <Step
            key={index}
            index={index}
            title={
              (typeof item === 'string' || item instanceof String)
              ? item.toString()
              : item.title
            }
            isInlineTitle={inline}
            isFirst={index === 0}
            isLast={index === steps.length - 1}
            isDisabled={(disabledSteps || []).includes(index)}
            isActive={index === activeStep}
            isCompleted={index < activeStep}
            activeColor={theme[color][variant]}
            completeColor={theme[color][variant]}
            defaultColor={theme.neutral.blackSky}
            size={size}
            iconName={
              !(typeof item === 'string' || item instanceof String)
              ? (item.icon || iconName)
              : iconName
            }
            inactiveIconName={
              !(typeof item === 'string' || item instanceof String)
              ? (item.inactiveIcon || item.icon || inactiveIconName)
              : inactiveIconName
            }
          />
        )) : <></>
      }
    </StepperContainer>
  );
};

const StepperContainer = styled.div`
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(2rem - 2px);
  width: 100%;
`;

export default Stepper;
