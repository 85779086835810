import React, { createContext, useState } from 'react';
import { nanoid } from 'nanoid';

import BannerMessage from './BannerMessage';

export type Context = {
  showBanner: (content: string, options: ShowBannerOptions) => string;
  removeBanner: (id: string) => void;
  removeAllBanners: () => void;
  updateBanner: (id: string, newContent: string, status: string) => void;
  bannerQueu: Array<Object>;
};

const defaultContext = {
  showBanner: () => '',
  removeBanner: () => {},
  removeAllBanners: () => {},
  updateBanner: () => {},
  bannerQueu: [],
}

export type BannerMessagesProviderProps = {
  /**
   * Unrelated app content
   */ 
  children: React.ReactNode;
  /**
   * By default the messages are displayed below the 'navbar'
   * but you can change it to 'viewport'
   */
  placement?: 'navbar' | 'viewport';
}

type ShowBannerOptions = {
  status?: 'default' | 'success' | 'info' | 'warning' | 'error';
  timeout?: number;
  id?: string;
  icon?: React.ReactNode;
  isFixed?: boolean,
}

export const BannerMessagesContext = createContext<Context>(defaultContext);

const BannerMessagesProvider = ({
  children,
}: BannerMessagesProviderProps) => {
  const [bannerQueu, setBannersQueu] = useState<any[]>([]);

  const showBanner = (content: string, options: ShowBannerOptions = {}): string => {
    const id = options.id || nanoid();
    setBannersQueu([
      ...bannerQueu,
      { content, ...options, id }
    ]);

    return id;
  };

  const removeBanner = (id: string) => {
    setBannersQueu(bannerQueu.filter(x => x.id !== id));
  };

  const removeAllBanners = () => {
    setBannersQueu([]);
  };

  const updateBanner = (id: string, newContent?: string, status?: string) => {
    setBannersQueu(
      bannerQueu.map(x => {
        if (id !== x.id) return x;
        return {
          ...x,
          content: newContent || x.content,
          status: status || x.status,
        }
      })
    )
  };

  return (
    <BannerMessagesContext.Provider value={{ showBanner, removeBanner, removeAllBanners, updateBanner, bannerQueu }}>
      {
        bannerQueu[0]
          ? (
            <BannerMessage
              message={bannerQueu[0].content}
              timeout={bannerQueu[0].timeout}
              status={bannerQueu[0].status}
              icon={bannerQueu[0].icon}
              onExit={() => removeBanner(bannerQueu[0].id)}
              isFixed={bannerQueu[0].isFixed}
            />
          )
          : null
      }
      {children}
    </BannerMessagesContext.Provider>
  );
};

export default BannerMessagesProvider;
