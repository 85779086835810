import { baseTheme, Theme } from './BaseTheme';

const lightTheme: Theme = {
  ...baseTheme,
  mode: 'light',
  background: {
    default: '#fafafa',
    paper: '#fff',
  },
  buttons: {
    hover: 'rgba(0, 0, 0, 0.04)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    active: 'rgba(0, 0, 0, 0.08)',
  },
  typography: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
};

export default lightTheme;
