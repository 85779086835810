import React, { CSSProperties } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

import {
  IconDefinition,
  faCamera,
  faCog,
  faCaretDown,
  faCogs,
  faBuilding,
  faSignOutAlt,
  faCircle,
  faInfoCircle as faInfoCircleOutlined
} from '@fortawesome/fontawesome-pro-regular';

import {
  faLockAlt,
  faCheck,
  faCheckCircle,
  faSearch,
  faTimes,
  faExclamationCircle,
  faInfoCircle,
  faPaperPlane,
  faPhoneSlash,
  faMusic,
  faAlarmClock,
  faTrashAlt,
  faShoppingCart,
  faSpinner,
  faCircle as faSolidCircle,
  faHome,
  faUsdCircle,
  faEnvelope,
  faUserCircle,
} from '@fortawesome/fontawesome-pro-solid';

export type iconKeyType = 'camera' | 'circle' | 'solidCircle' | 'check' | 'search' | 'lock' | 'exclamationCircle' | 'infoCircle'
  | 'paperPlane' | 'phoneSlash' | 'music' | 'alarmClock' | 'trash' | 'shoppingCart' | 'checkCircle'
  | 'cog' | 'cogs' | 'caretDown' | 'building' | 'signOut' | 'spinner' | 'times' | 'home' | 'usdCircle'
  | 'userCircle' | 'envelope' | 'infoCircleOutlined';

type tintType = 'black' | 'white' | 'red';

type iconsType = {
  [key in iconKeyType]: IconDefinition;
};

type iconPropsType = {
  iconName: iconKeyType;
  iconSize?: SizeProp;
  style?: CSSProperties;
  tint?: tintType;
  onClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
  className?: string;
};

const ICONS: iconsType = {
  camera: faCamera,
  circle: faCircle,
  solidCircle: faSolidCircle,
  check: faCheck,
  checkCircle: faCheckCircle,
  lock: faLockAlt,
  search: faSearch,
  exclamationCircle: faExclamationCircle,
  infoCircle: faInfoCircle,
  infoCircleOutlined: faInfoCircleOutlined,
  paperPlane: faPaperPlane,
  phoneSlash: faPhoneSlash,
  music: faMusic,
  alarmClock: faAlarmClock,
  trash: faTrashAlt,
  shoppingCart: faShoppingCart,
  cog: faCog,
  cogs: faCogs,
  caretDown: faCaretDown,
  building: faBuilding,
  signOut: faSignOutAlt,
  spinner: faSpinner,
  times: faTimes,
  userCircle: faUserCircle,
  home: faHome,
  usdCircle: faUsdCircle,
  envelope: faEnvelope,
};

const Icon: React.FunctionComponent<iconPropsType> = ({ 
  iconName, 
  iconSize = '1x',
  tint = 'black',
  className,
  ...props 
}: iconPropsType) => {
  if (!(iconName in ICONS)) return null;

  return <FontAwesomeIcon 
    size={iconSize}
    icon={ICONS[iconName] as IconProp}
    color={tint}
    className={
      `
        ${typeof className !== 'undefined' ? className : ''}
      `
    }
    {...props} />;
};

export default Icon;
