import React, { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';
import { useTheme } from '../../hooks';

type BannerMessageProps = {
  message: string;
  onExit: Function;
  icon?: React.ReactNode;
  customContent?: React.ReactNode;
  timeout?: number;
  status?: 'default' | 'info' | 'warning' | 'error';
  isFixed?: boolean;
};

const BannerMessage = ({
  message,
  icon,
  /* customContent, */
  status = 'default',
  timeout = 2500,
  onExit,
  isFixed = false,
}: BannerMessageProps) => {
  const [showBanner, setShowBanner] = useState(true);
  const theme = useTheme();

  const close = () => {
    setShowBanner(false);
    setTimeout(() => onExit(), 600)
  }

  useEffect(() => {
    if (showBanner && !isFixed) {
      setTimeout(() => {
        setShowBanner(false);
      }, timeout);
    }
  }, [showBanner]);

  const exit = () => {
    onExit()
    setTimeout(() => {
      setShowBanner(true);
    }, 100);
  }

  return (
    <Transition
      in={showBanner}
      timeout={{
        appear: 150,
        exit: 600,
      }}
      unmountOnExit
      appear
      onExited={() => exit()}
    >
      {
        state => {
          return (
            <MessageContainer
              className={`
                ${state}
                ${status}
              `}
              theme={theme}
            >
              { 
                icon && (
                  <IconContainer>
                    {icon}
                  </IconContainer>
                ) 
              }
              <Message>{message}</Message>
              <CloseIcon onClick={close} />
            </MessageContainer>
          );
        }
      }
    </Transition>
  );
};

const MessageContainer = styled.div`
  color: white;
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  display: flex;
  flex-direction: row;
  height: 70px;
  width: 100%;
  align-items: center;
  justify-content: center;
  top: 0px;
  transition: all 0.5s;
  z-index: 1;
  &.entered {
    top: 70px;
    transition: all 500ms;
  }
  &.exiting {
    top: 0;
    transition: all 500ms;
  }
  &.default {
    background-color: #c3c3c3;
  }
  &.success {
    background-color: ${props => props.theme.success.main};
  }
  &.error {
    background-color: ${props => props.theme.error.main};
  }
  &.warning {
    background-color: ${props => props.theme.warning.main};
  }
  &.info {
    background-color: ${props => props.theme.info.main};
  }
`;

const Message = styled.h1`
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
`;

const IconContainer = styled.div`
  padding: 0;
  margin: 0 1rem 0 0;

  & svg {
    color: inherit;
  }
`;

const CloseIcon = styled.a`
  position: absolute;
  right: 35px;
  top: 26px;
  width: 18px;
  height: 18px;
  opacity: 0.8;
  transition: 0.25s;
  cursor: pointer;
  &:hover {
    transform: scale(1.15);
    opacity: 1;
  }
  &:before, &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 18px;
    width: 2px;
    background-color: #fff;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export default BannerMessage;
