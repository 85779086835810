import React from 'react';
import styled from 'styled-components';

type ButtonBaseProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: (e: Event) => any;
  onMouseEnter?: (e: Event) => any;
  onMouseLeave?: (e: Event) => any;
  disabled?: boolean;
}

const ButtonBase = React.forwardRef(({
  children,
  className = '',
  onClick,
  onMouseEnter,
  onMouseLeave,
  disabled,
}: ButtonBaseProps, ref) => (
  <Button
    onClick={
      (e: Event) => {
        if (onClick) onClick(e);
      }
    }
    onMouseEnter={
      (e: Event) => {
        if (onMouseEnter) onMouseEnter(e);
      }
    }
    onMouseLeave={
      (e: Event) => {
        if (onMouseLeave) onMouseLeave(e);
      }
    }
    className={`
      ${disabled ? 'disabled' : ''}
      ${className}
    `}
    ref={ref}
  >
    {children}
  </Button>
));

const Button = styled.button<any>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-decoration: none;
  color: inherit;

  & svg {
    color: inherit;
  }
  
  &::-moz-focus-inner {
    border-style: none;
  };
  &.disabled {
    pointer-events: none;
    cursor: default !important;
  };
  @media print {
    color-adjust: exact;
  };
`;

export default ButtonBase;