import React from 'react';
import styled from 'styled-components';

export type InputBaseProps = {
  autoFocus?: boolean;
  classes?: string;
  className?: string;
  /* defaultValue: unknown; */
  disabled?: boolean;
  /* error: boolean; */
  fullWidth?: boolean;
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  value?: any;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  placeholder?: string;
  readOnly?: boolean;
  type?: string;
  /* value: unknown; */
}

const InputBase = React.forwardRef(({
  autoFocus,
  classes = '',
  className,
  disabled,
  fullWidth,
  inputProps = {},
  onBlur,
  onClick,
  onFocus,
  onKeyDown,
  onKeyUp,
  placeholder,
  readOnly,
  type = 'text',
  value,
  onChange,
}: InputBaseProps, ref: React.Ref<any>) => {
  const calculatedProps: any = {};

  if (value) calculatedProps.value = value;
  if (onChange) calculatedProps.onChange = onChange;

  return <Input
    autoFocus={autoFocus}
    className={`
      ${className}
      ${fullWidth && 'fullWidth'}
    `}
    classes={classes}
    ref={ref}
    type={type}
    readOnly={readOnly}
    placeholder={placeholder}
    disabled={disabled}
    onBlur={onBlur && onBlur}
    onClick={onClick && onClick}
    onFocus={onFocus && onFocus}
    onKeyUp={onKeyUp && onKeyUp}
    onKeyDown={onKeyDown && onKeyDown}
    value={value}
    onChange={onChange}
    {...calculatedProps}
    {...inputProps}
  />
});

type StyledInputProps = {
  classes: string;
}

const Input = styled.input<StyledInputProps>`
  outline: 0;
  border: none;
  ${props => props.classes}
`;

export default InputBase;
