import { useContext } from 'react';

import { PaperThemeContext } from '../theme/PaperThemeProvider';
import { Theme } from '../theme';

function useTheme(): Theme {
  const context = useContext(PaperThemeContext);

  if (!context) {
    throw new Error(`useTheme must be used within a PaperThemeProvider`);
  }

  return (context as any);
}

export default useTheme;
