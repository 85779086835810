import React from 'react';
import styled from 'styled-components';

export type NavbarRightGroupProps = {
  children?: React.ReactNode;
  className?: string;
};

const NavbarRightGroup = ({
  children,
  className = '',
}: NavbarRightGroupProps) => {
  return (
    <GroupContainer
      className={className}
    >
      {children && children}
    </GroupContainer>
  );
};

const GroupContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  align-self: flex-end;
  justify-content: flex-end;
`;

export default NavbarRightGroup;
